<template>
  <v-main>
    <v-container fluid page v-if="!loading">
      <v-row align="center" justify="center">
        <eximyr-breadcrumb :breadcrumbs="breadcrumbs"></eximyr-breadcrumb>

        <v-col cols="12">
          <h2 v-if="create" class="display-1 font-weight-light pl-5">
            Crear nuevo producto
          </h2>
          <h2 v-else class="display-1 font-weight-light pl-5">
            Editar producto
          </h2>
        </v-col>

        <v-col cols="12" v-if="error">
          <v-alert border="left" colored-border type="error" elevation="2">
            <strong v-if="code">{{ code }}</strong>
            {{ message }}
          </v-alert>
        </v-col>
      </v-row>

      <v-row class="mb-6">
        <v-col cols="12">
          <v-card>
            <v-card-text>
              <v-row class="flex-row-reverse">
                <!-- Preview card -->
                <v-col cols="12" md="5" lg="4">
                  <div class="text-center headline font-weight-thin mt-5 mb-3">
                    Vista previa
                  </div>

                  <v-card class="mx-auto header category" max-width="450">
                    <v-img
                      class="white--text"
                      height="200px"
                      :src="computedImage"
                    >
                      <v-card-title
                        class="align-content-space-between fill-height pa-0"
                      >
                        <div class="pa-3 text-truncate text-right options">
                          <v-chip class="ma-2 elevation-3" color="white">
                            <v-avatar>
                              <v-icon>{{
                                active ? 'mdi-eye' : 'mdi-eye-off'
                              }}</v-icon>
                            </v-avatar>
                          </v-chip>

                          <v-chip class="ma-2 elevation-3" color="white">
                            <v-avatar>
                              <v-icon>mdi-currency-eur</v-icon>
                            </v-avatar>
                            <strong>{{ price | dinero }}</strong>
                          </v-chip>
                        </div>
                        <div class="title pa-3 text-truncate">
                          {{ computedName }}
                        </div>
                      </v-card-title>
                    </v-img>
                    <v-card-text>
                      <span>Descripción</span>
                      <div class="text--primary text-truncate mb-1">
                        {{ computedDescription }}
                      </div>

                      <v-avatar
                        size="32"
                        color="white"
                        class="mr-1 mb-2 elevation-1"
                        v-for="allergen in allergens"
                        :key="allergen"
                      >
                        <v-tooltip top>
                          <template v-slot:activator="{ on }">
                            <img
                              v-on="on"
                              :src="allergenImage(allergen)"
                              :alt="allergenName(allergen)"
                            />
                          </template>
                          <span>{{ allergenName(allergen) }}</span>
                        </v-tooltip>
                      </v-avatar>
                    </v-card-text>
                  </v-card>

                  <div v-if="isAdmin || isEmployee">
                    <v-btn
                      :disabled="
                        translatedName == name[$i18n.locale] &&
                        translatedDescription == description[$i18n.locale]
                      "
                      block
                      max-width="400"
                      color="primary"
                      @click="googleTranslate"
                    >
                      Traducir Google
                    </v-btn>
                  </div>
                </v-col>
                <!-- Preview card -->

                <v-col cols="12" md="7" lg="8">
                  <v-form ref="form">
                    <v-row>
                      <v-col cols="12" class="py-0">
                        <v-file-input
                          accept="image/*"
                          label="Seleccione una imagen"
                          @change="onFilePicked"
                          validate-on-blur
                          :rules="rules.image"
                        ></v-file-input>
                      </v-col>

                      <v-col cols="12" class="py-0">
                        <v-card
                          outlined
                          class="my-2"
                          v-for="l in languages"
                          :key="l.code"
                        >
                          <v-card-text>
                            <v-card-title class="subtitle-1">{{
                              l.language
                            }}</v-card-title>

                            <v-text-field
                              label="Nombre del producto *"
                              :counter="150"
                              prepend-icon="mdi-label-outline"
                              required
                              v-model="name[l.code]"
                              :rules="rules.name"
                            >
                              <template v-slot:append>
                                <img
                                  width="24"
                                  height="24"
                                  :src="l.image_path"
                                  :alt="l.language"
                                />
                              </template>
                            </v-text-field>

                            <v-textarea
                              :counter="255"
                              prepend-icon="mdi-file-document-edit-outline"
                              label="Descripción del producto"
                              v-model="description[l.code]"
                              :rules="rules.description"
                            >
                              <template v-slot:append>
                                <img
                                  width="24"
                                  height="24"
                                  :src="l.image_path"
                                  :alt="l.language"
                                />
                              </template>
                            </v-textarea>
                          </v-card-text>
                        </v-card>
                      </v-col>

                      <v-col cols="12" class="py-0">
                        <v-select
                          v-model="allergens"
                          :items="allergensList"
                          label="¿Qué alérgenos contiene este producto?"
                          multiple
                          chips
                          item-value="id"
                          :item-text="itemText"
                          prepend-icon="mdi-peanut-outline"
                          deletable-chips
                        ></v-select>
                      </v-col>

                      <v-col cols="12" class="py-0">
                        <v-text-field
                          label="Precio del producto (en centimos) *"
                          :counter="10"
                          required
                          prepend-icon="mdi-currency-eur"
                          v-model="price"
                          :rules="rules.price"
                        ></v-text-field>
                      </v-col>

                      <v-col cols="12" class="py-0">
                        <v-switch
                          :prepend-icon="active ? 'mdi-eye' : 'mdi-eye-off'"
                          v-model="active"
                          label="Activar el producto"
                        ></v-switch>
                        <small class="text--primary">
                          (*) Indica los campos obligatorios.
                        </small>
                      </v-col>

                      <v-col cols="12" class="text-right">
                        <v-btn class="mr-2" @click="cancel">Cancelar</v-btn>
                        <v-btn color="primary" @click="save">Guardar</v-btn>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <!-- Loading screen -->
    <eximyr-loading
      :loading="loading || loadingLanguages || loadingTranslation"
    ></eximyr-loading>
    <!-- Loading screen -->
  </v-main>
</template>

<script>
import goTo from 'vuetify/lib/services/goto';
import errors from '../mixins/errors';

import { IMAGE_DEFAULT_PRODUCT } from '@/constants';
import { mapGetters } from 'vuex';

import { required } from '@/validators/required';
import { length } from '@/validators/length';
import { numeric } from '@/validators/numeric';
import { fileSize } from '@/validators/fileSize';

// Language loader mixin
import languages from '../mixins/languages';

import EximyrBreadcrumb from '../components/EximyrBreadcrumb';
import EximyrLoading from '../components/EximyrLoading';

export default {
  components: { EximyrBreadcrumb, EximyrLoading },

  mixins: [languages, errors],

  data: () => ({
    create: false,

    id: 0,
    restaurant_uuid: 0,
    category_id: 0,
    image: null,

    // All languages are needed so we can have reactivity
    name: { de: null, en: null, es: null, it: null, fr: null, pt: null },
    description: { de: null, en: null, es: null, it: null, fr: null, pt: null },

    allergens: [],
    price: '',
    active: true,

    // We need all allergens here
    allergensList: [],

    imageName: '',
    imageFile: '',

    loadingTranslation: false,
    translatedName: '', // Prevent multiple clicks
    translatedDescription: '', // Prevent multiple clicks
  }),

  computed: {
    ...mapGetters('security', {
      isAdmin: 'isAdmin',
      isEmployee: 'isEmployee',
    }),

    ...mapGetters('categories', {
      category: 'category',
    }),

    ...mapGetters('products', {
      product: 'product',
      loading: 'loading',
    }),

    itemText() {
      // Needed in the select for category substitution (remove category feature)
      return 'name[' + this.$i18n.locale + ']';
    },

    breadcrumbs() {
      const breadcrumbs = [
        {
          text: this.$t('navigation.categories'),
          disabled: false,
          exact: true,
          to: { name: 'categories' },
        },
        {
          text: this.categoryName,
          disabled: false,
          exact: true,
          to: { name: 'products' },
        },
      ];

      if (this.create) {
        breadcrumbs.push({
          text: this.$t('products.breadcrumb.create'),
          disabled: true,
          exact: false,
        });
      } else {
        breadcrumbs.push({
          text: this.$t('products.breadcrumb.edit', {
            name: this.computedName,
          }),
          disabled: true,
          exact: false,
        });
      }

      return breadcrumbs;
    },

    categoryName() {
      if (!this.category.name) return '';
      return this.category.name[this.$i18n.locale]
        ? this.category.name[this.$i18n.locale]
        : this.category.name[this.$i18n.fallbackLocale];
    },
    computedImage() {
      // We cannot use a constant in the src of an image so we
      // must create a computed property
      return this.image ? this.image : IMAGE_DEFAULT_PRODUCT;
    },
    computedName() {
      return this.name[this.$i18n.locale]
        ? this.name[this.$i18n.locale]
        : this.name[this.$i18n.fallbackLocale];
    },
    computedDescription() {
      return this.description[this.$i18n.locale]
        ? this.description[this.$i18n.locale]
        : this.description[this.$i18n.fallbackLocale];
    },

    // Validation errors
    rules() {
      return {
        image: [fileSize(this, 512)],
        name: [required(this), length(this, 150)],
        description: [length(this, 255)],
        price: [required(this), numeric(this), length(this, 10)],
      };
    },
  },

  created() {
    // First we load the category (we need to load a category
    // for the route to work on its own - reload)
    this.$store.dispatch('categories/loadCategory', {
      restaurant: this.$route.params.restaurant,
      category: this.$route.params.category,
    });

    // Now we load all allergens
    this.$store
      .dispatch('allergens/all', this.$route.params.restaurant)
      .then(response => {
        this.allergensList = response.data;
      })
      .catch(error => {
        this.storeError(error);
      });

    // Check if we are in edit mode or in create mode
    if (!this.$route.params.product) {
      this.create = true;
      return;
    }

    this.$store
      .dispatch('products/loadProduct', {
        restaurant: this.$route.params.restaurant,
        category: this.$route.params.category,
        product: this.$route.params.product,
      })
      .then(response => {
        this.id = response.data.id;
        this.restaurant_uuid = response.data.restaurant_uuid;
        this.category_id = response.data.category_id;
        this.image = response.data.image_path;

        // We need to copy all data from one to another - using this
        // for we preserve older data
        let locale;
        for (locale in response.data.name) {
          this.name[locale] = response.data.name[locale];
        }
        for (locale in response.data.description) {
          this.description[locale] = response.data.description[locale];
        }

        this.price = response.data.price;
        this.active = response.data.active;

        // Load only id of all allergens
        this.allergens = response.data.allergens.map(allergen => allergen.id);
      })
      .catch(error => {
        this.storeError(error);
      });
  },

  methods: {
    cancel() {
      this.$router.push({
        name: 'products',
      });
    },

    save() {
      if (!this.$refs.form.validate()) {
        goTo(0);
        return;
      }

      let product = {
        restaurant_uuid: this.$route.params.restaurant,
        category_id: this.$route.params.category,
        image: this.imageFile,
        name: this.name,
        description: this.description,
        price: this.price,
        allergens: this.allergens,
        active: this.active,
      };

      let action = '';
      if (this.create) {
        // Create new product
        action = 'products/create';
      } else {
        // Update the product - set the product's id
        product.id = this.product.id;
        action = 'products/update';
      }

      // Update / create the product
      this.$store
        .dispatch(action, product)
        .then(() => {
          // Once created, go back to the products page
          this.$router.push({
            name: 'products',
          });
        })
        .catch(error => {
          goTo(0);
          this.storeError(error);
        });
    },

    onFilePicked(file) {
      if (file) {
        const fr = new FileReader();
        fr.readAsDataURL(file);
        fr.addEventListener('load', () => {
          this.image = fr.result;
          this.imageFile = file; // this is an image file that can be sent to server...
        });
      } else {
        this.imageFile = '';

        // We must show the latest image, if its a create action
        // now then use the default image
        this.image = this.create ? null : this.product.image_path;
      }
    },

    allergenImage(id) {
      const allergen = this.allergensList.find(allergen => allergen.id == id);

      if (allergen) return allergen.image_path;
    },
    allergenName(id) {
      const allergen = this.allergensList.find(allergen => allergen.id == id);

      if (allergen)
        return allergen.name[this.$i18n.locale]
          ? allergen.name[this.$i18n.locale]
          : allergen.name[this.$i18n.fallbackLocale];
    },

    googleTranslate() {
      this.loadingTranslation = true;
      this.clearErrors();
      // Get spanish translations
      // console.log(this.name[this.$i18n.locale]);
      // console.log(this.description[this.$i18n.locale]);

      const payload = {
        input: [
          this.name[this.$i18n.locale],
          this.description[this.$i18n.locale],
        ],
        source: this.$i18n.locale,
        target: Object.keys(this.name).filter(
          locale => locale !== this.$i18n.locale
        ),
      };

      this.$store
        .dispatch('translation/translate', payload)
        .then(response => {
          Object.keys(this.name)
            // Remove the source locale (es)
            .filter(locale => locale !== this.$i18n.locale)
            .forEach(code => {
              this.name[code] = response.data[code]['0'].text;
              if (response.data[code]['1']) {
                this.description[code] = response.data[code]['1'].text;
              }
            });

          this.translatedName = this.name[this.$i18n.locale];
          this.translatedDescription = this.description[this.$i18n.locale];
        })
        .catch(error => {
          this.storeError(error);
          this.error(error.response);
        })
        .finally(() => {
          this.loadingTranslation = false;
        });
    },
  },
};
</script>

<style></style>
