<template>
  <v-main>
    <v-container fluid page>
      <v-row align="center">
        <eximyr-breadcrumb :breadcrumbs="breadcrumbs"></eximyr-breadcrumb>

        <v-col cols="12">
          <h2 class="display-1 font-weight-light pl-5">Productos</h2>
        </v-col>
      </v-row>

      <v-row v-show="!loading" align="center">
        <v-col cols="12" v-if="error">
          <v-alert border="left" colored-border type="error" elevation="2">
            <strong v-if="code">{{ code }}</strong>
            {{ message }}
          </v-alert>
        </v-col>

        <v-col cols="12">
          <v-alert
            :value="products.length == 0"
            border="left"
            colored-border
            type="info"
            elevation="2"
            >{{ $t('products.noProducts') }}</v-alert
          >
        </v-col>
      </v-row>

      <draggable
        v-show="!loading"
        v-model="products"
        handle=".handle"
        tag="div"
        class="row align-stretch"
        v-bind="dragOptions"
        @start="drag = true"
        @end="drag = false"
      >
        <!-- Products cards -->
        <v-col
          cols="12"
          sm="6"
          md="4"
          xl="3"
          class="d-flex"
          v-for="product in products"
          :key="product.id"
        >
          <EasyServeProduct
            :product="product"
            @product-delete="remove(product)"
            @product-edit="edit(product)"
          ></EasyServeProduct>
        </v-col>
        <!-- Products cards -->
      </draggable>

      <!-- Create fab button -->
      <v-fab-transition>
        <v-btn
          color="red"
          fab
          dark
          large
          bottom
          right
          ripple
          fixed
          @click="create"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-fab-transition>
    </v-container>

    <!-- Remove buttom sheet -->
    <v-bottom-sheet v-model="removeDialog">
      <v-sheet class="text-center" height="200px" v-if="product">
        <div class="pt-6 px-3">
          ¿Estás segur@ que quieres eliminar
          <span class="font-weight-medium"
            >'{{ product.name | translate($i18n) }}'</span
          >?
        </div>

        <v-btn class="mt-6 mx-2" @click="removeCanceled">Cancelar</v-btn>
        <v-btn class="mt-6 mx-2" color="error" @click="removeConfirmed">
          Eliminar
        </v-btn>
      </v-sheet>
    </v-bottom-sheet>
    <!-- Remove buttom sheet -->

    <!-- Loading screen -->
    <eximyr-loading :loading="loading"></eximyr-loading>
    <!-- Loading screen -->
  </v-main>
</template>

<script>
import draggable from 'vuedraggable';

import errors from '../mixins/errors';
import { mapGetters } from 'vuex';

import EximyrBreadcrumb from '../components/EximyrBreadcrumb';
import EximyrLoading from '../components/EximyrLoading';

import EasyServeProduct from './components/EasyServeProduct';

export default {
  mixins: [errors],

  components: {
    draggable,
    EximyrBreadcrumb,
    EximyrLoading,
    EasyServeProduct,
  },

  data: () => ({
    product: null,
    removeDialog: false,
  }),

  computed: {
    ...mapGetters('products', {
      category: 'category',
      loading: 'loading',
    }),

    products: {
      get() {
        return this.$store.state.products.products;
      },

      set(products) {
        this.reorder(products);
        // products.forEach(product => console.log(product.name.es));
      },
    },

    breadcrumbs() {
      return [
        {
          text: this.$t('navigation.categories'),
          disabled: false,
          exact: true,
          to: { name: 'categories' },
        },
        {
          text: this.categoryName,
          disabled: true,
          exact: false,
          to: { name: 'products' },
        },
      ];
    },

    categoryName() {
      if (!this.category.name) return '';
      return this.category.name[this.$i18n.locale]
        ? this.category.name[this.$i18n.locale]
        : this.category.name[this.$i18n.fallbackLocale];
    },

    dragOptions() {
      return {
        animation: 200,
        group: 'products',
        disabled: false,
        ghostClass: 'ghost',
      };
    },
  },

  created() {
    this.load();
  },

  beforeDestroy() {
    this.$store.dispatch('products/clear');
  },

  methods: {
    load() {
      // Create the payload
      const payload = {
        restaurant: this.$route.params.restaurant,
        category: this.$route.params.category,
      };

      this.$store
        .dispatch('products/load', payload)
        .then(() => {
          this.clearErrors();
        })
        .catch(error => {
          this.storeError(error);
        });
    },

    // Create product
    create() {
      this.$router.push({
        name: 'products.create',
      });
    },

    // Edit products
    edit(product) {
      // Clear all products and move to the edit page
      this.$store.dispatch('products/clear').then(() =>
        this.$router.push({
          name: 'products.edit',
          params: {
            restaurant: this.$route.params.restaurant,
            category: this.$route.params.category,
            product: product.id,
          },
        })
      );
    },

    // Reorder products
    reorder(products) {
      //
      const payload = {
        restaurant: this.$route.params.restaurant,
        category: this.$route.params.category,
        products: products,
      };

      this.$store
        .dispatch('products/reorder', payload)
        .then(() => {
          // Clear all errors
          this.clearErrors();
        })
        .catch(error => {
          this.storeError(error);
        });
    },

    remove(product) {
      this.product = product;
      this.removeDialog = true;
    },
    removeCanceled() {
      this.product = null;
      this.removeDialog = false;
    },
    removeConfirmed() {
      const payload = {
        restaurant: this.$route.params.restaurant,
        product: this.product.id,
      };

      // Remove this product and reload
      this.$store
        .dispatch('products/remove', payload)
        .then(() => {
          // Once deleted, reload all products in this category
          this.load();
        })
        .catch(error => {
          this.storeError(error);
        });

      this.removeCanceled();
    },
  },
};
</script>
