export default {
  data: () => ({
    languages: [],
    loadingLanguages: false,
  }),

  created() {
    this.load();
  },

  methods: {
    load: function () {
      // Load languages
      this.loadingLanguages = true;
      this.$store
        .dispatch('languages/loadForRestaurant', this.$route.params.restaurant)
        .then(response => {
          this.languages = response.data;
        })
        .finally(() => {
          this.loadingLanguages = false;
        });
    },
  },
};
