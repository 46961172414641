<template>
  <!-- Start Breadcrumb navigation -->
  <v-col cols="12">
    <v-breadcrumbs :items="breadcrumbs">
      <template v-slot:divider>
        <v-icon>mdi-chevron-right</v-icon>
      </template>

      <template v-slot:item="props">
        <v-breadcrumbs-item
          :exact="props.item.exact"
          replace
          :to="props.item.to"
          :class="[props.item.disabled && 'disabled']"
          class="subheading"
        >
          {{ props.item.text }}
        </v-breadcrumbs-item>
      </template>
    </v-breadcrumbs>
  </v-col>
  <!-- End breadcrumb navigation -->
</template>

<script>
export default {
  props: {
    breadcrumbs: Array,
  },
};
</script>

<style></style>
