<template>
  <v-card
    class="mx-auto header category d-flex flex flex-column"
    ripple
    @click="$emit('product-edit')"
  >
    <v-img
      class="white--text grey darken-3 flex-grow-0"
      height="200px"
      :src="computedImage"
    >
      <v-card-title class="align-content-space-between fill-height pa-0">
        <div class="pa-3 text-truncate text-right options">
          <v-chip class="ma-2 elevation-3 handle" color="white" @click.stop>
            <v-avatar left>
              <v-icon>mdi-sort</v-icon>
            </v-avatar>
            {{ product.display_priority }}
          </v-chip>

          <v-chip class="ma-2 elevation-3" color="white">
            <v-avatar>
              <v-icon>{{ product.active ? 'mdi-eye' : 'mdi-eye-off' }}</v-icon>
            </v-avatar>
            <!-- {{ product.active ? 'Activo' : 'No visible' }} -->
          </v-chip>

          <v-chip class="ma-2 elevation-3" color="white">
            <v-avatar>
              <v-icon>mdi-currency-eur</v-icon>
            </v-avatar>
            <strong>{{ product.price | dinero }}</strong>
          </v-chip>
        </div>
        <div class="title pa-3 text-truncate">
          {{ product.name | translate($i18n) }}
        </div>
      </v-card-title>
    </v-img>

    <v-card-text class="pb-0 flex-grow-1">
      <span>Descripción</span>
      <div
        v-if="!!$options.filters.translate(product.description, $i18n)"
        class="text--primary text-truncate mb-1"
      >
        {{ product.description | translate($i18n) }}
      </div>
      <div v-else class="text--primary text-truncate font-italic mb-1">
        {{ $t('products.product.noDescription') }}
      </div>

      <v-avatar
        size="32"
        color="white"
        class="mr-1 mb-2 elevation-1"
        v-for="allergen in product.allergens"
        :key="product.id + '_allergen_' + allergen.id"
      >
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <img
              :src="allergen.image_path"
              v-on="on"
              :alt="allergen.name[$i18n.locale]"
            />
          </template>
          <span>{{ allergen.name[$i18n.locale] }}</span>
        </v-tooltip>
      </v-avatar>
    </v-card-text>

    <v-card-actions>
      <v-spacer></v-spacer>

      <v-btn
        fab
        small
        dark
        color="red"
        @click.stop.prevent="$emit('product-delete')"
      >
        <v-icon>mdi-delete</v-icon>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { IMAGE_DEFAULT_PRODUCT } from '@/constants';

export default {
  props: {
    product: Object,
  },

  data: () => ({}),

  computed: {
    computedImage() {
      return this.product.image
        ? this.product.image_path
        : IMAGE_DEFAULT_PRODUCT;
    },
  },
};
</script>

<style></style>
